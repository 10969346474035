import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';
import logo from '../../ekultur.png';

import { 
    FormSection,
    SectionHeader,
    SectionBody,
    FederatedButtons,
    Link,
} from 'aws-amplify-react';


// HACK: 
// Need to use different import for these UI-component as they are 
// not defined as exports within the global scope of the component.
import { 
    FormField, 
    Input, 
    InputLabel, 
    Hint, 
    SectionFooter, 
    SectionFooterPrimaryContent, 
    Button, 
    SectionFooterSecondaryContent 
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';
 
export default class CustomForgotPassword extends ForgotPassword {
    showComponent(theme) {
        const { authState, hide, authData={} } = this.props;
        if (hide && hide.includes(CustomForgotPassword)) { return null; }

        return (
            <div className="form-container">
                <header>
                    <img src={logo} alt="logo" className="logo" />
                </header>
                <FormSection theme={theme}>
                    <SectionHeader theme={theme}>{I18n.get('Reset your password')}</SectionHeader>
                    <SectionBody>
                        { this.state.delivery || authData.username ? this.submitView() : this.sendView() }
                    </SectionBody>
                    <SectionFooter theme={theme}>
                        <SectionFooterPrimaryContent theme={theme}>
                            { this.state.delivery || authData.username ? 
                                <Button theme={theme} onClick={this.submit}>{I18n.get('Submit')}</Button> :
                                <Button theme={theme} onClick={this.send}>{I18n.get('Send Code')}</Button>
                            }
                        </SectionFooterPrimaryContent>
                        <SectionFooterSecondaryContent theme={theme}>
                            { this.state.delivery || authData.username ?
                                <Link theme={theme} onClick={this.send}>{I18n.get('Resend Code')}</Link> :
                                <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                    {I18n.get('Back to Sign In')}
                                </Link>
                            }
                        </SectionFooterSecondaryContent>
                    </SectionFooter>
                </FormSection>
            </div>
        );
    }
}
