// AWS Amplify texts translated to norwegian. 
// Toasts are not being translated, reported as: 
// https://github.com/aws-amplify/amplify-js/issues/2106

const norwegianTexts = {
    'Username': "Brukernavn",
    'Password': "Passord",
    'Create account': 'Opprett konto',
    'Reset password': 'Nullstill passord',
    'Sign in to your account': 'Logg inn med din brukerkonto',    
    'Sign In': 'Logg på',
    'Enter your username': 'Skriv inn ditt brukernavn',
    'Enter your password': 'Skriv inn ditt passord',
    'Forget your password? ': 'Glemt passord? ',
    'No account? ': 'Ingen konto? ',
    'Username cannot be empty': 'Du må fylle inn et brukernavn',
    'User does not exist': 'Brukeren eksisterer ikke',
    'Incorrect username or password': 'Feil brukernavn/passord',
    'Reset your password': 'Nullstill passordet',
    'Back to Sign In': 'Tilbake',
    'Send Code': 'Send kode',
    'Create a new account': 'Opprett ny konto',
    'Create a username': 'Skriv inn brukernavn',
    'Create a password': 'Skriv inn passordet',
    'Email Address': 'E-post adresse',
    'Phone Number': 'Telefonnummer',
    'Create Account': 'Opprett konto',
    'Have an account? ': 'Har du allerede en konto? ',
    'Sign in': 'Logg på',
    'Password cannot be empty': 'Feltet "Passord" må ha en verdi',
    'Invalid password format': 'Ugyldig passordformat',
    'Attribute value for phone_number must not be null': 'Feltet "Telefonnummer" må ha en verdi',
    'Invalid email address format.': 'Ugyldig e-post adresse format',
    'Confirm Sign Up': 'Bekreft registrering',
    'Confirmation Code': 'Bekreftelseskode',
    'Enter your code': 'Skriv inn bekreftelseskoden',
    'Lost your code? ': 'Mistet koden? ',
    'Resend Code': 'Send kode på nytt',
    'Confirm': 'Bekreft',
    'Invalid verification code provided, please try again.': 'Ugyldig verifiseringskode, vennligst forsøk på nytt.',
    'Sign In with Facebook': 'Logg på med Facebook',
    'Family name': 'Etternavn',
    'Given name': 'Fornavn',
    'or': 'eller',
    'User account has expired, it must be reset by an administrator.': 'Kontoen er utløpt, og må nullstilles av en administrator.',
    'Hello': 'Velkommen',
    'Sign Out': 'Logg ut',
    'Sign In with Google': 'Logg på med Google'
  };
 
  export default norwegianTexts;