import React from 'react';

import './styles.css';

export default class Loader extends React.Component {
    render() {
        if (this.props.show) {
            return (
                <div>
                    <div className="spinner">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                        <div className="rect4"></div>
                        <div className="rect5"></div>
                    </div>
                    <div className="info">
                        Verifiserer bruker...
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }
}