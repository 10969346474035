import AppConstants from '../constants';

/**
 * Class containing methods used when authorizing a newly created
 * user by entering username and password against the Cura database.
 */
export default class AuthorizeUser {
    constructor(appUUID) {
        this._data = {
            appUUID: appUUID
        };
    }

    /**
     * Initializes this object.
     * @param {object} user 
     */
    init(user) {
        this._parseUser(user);
        return new Promise( (resolve, reject) => {
            let url = `${AppConstants.restApiUrl}authorize`;
            fetch(url, {
                method: 'GET',
                headers: {
                    "ek-data": JSON.stringify(this._data)
                },
            })
            .then(response => {
                let res = response.blob();
                return res;
            })
            .then(res => {
                let jwt = '',
                    fr = new FileReader();

                fr.onload = e => {
                    jwt = fr.result;

                    if (jwt === '') {
                        reject('Failed to fetch token.');
                    } else {
                        resolve(jwt);
                    }
                };

                fr.readAsText(res);
            })
            .catch(err => reject(err));
        });
    }

    /**
     * Parses the user object, extracting required data.
     * @param {object} user 
     */
    _parseUser(user) {
        let obj = {};
        obj.username = user.attributes.email;
        obj.givenName = user.attributes.given_name;
        obj.familyName= user.attributes.family_name;
        obj.sourceUserId = user.attributes.sub;
        obj.source = 'aws cognito';

        this._data = Object.assign(this._data, obj);
    }
}