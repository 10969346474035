const Appconstants = {
    'restApiUrl': 'https://dev.restapi.auth.ekultur.org/',
    'facebookAppID': '199784297261299',
    'googleAppID': '1004281352618-2bdv20fu1nrfkacskoihten3hajtfsh5.apps.googleusercontent.com',
    'identityPoolId': 'eu-west-1:e19b37a6-0fee-414c-9c4a-019f305852ef',
    'region': 'eu-west-1',
    'userPoolId': 'eu-west-1_kWpN3Kp0C',
    'userPoolWebClientId': '1u50dalutj16plijanhbgcvar3'
};

export default Appconstants;