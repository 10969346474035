import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';
import logo from '../../ekultur.png';

import { 
    FormSection,
    SectionHeader,
    SectionBody,
    FederatedButtons,
    Link,
} from 'aws-amplify-react';

// HACK: 
// Need to use different import for these UI-component as they are 
// not defined as exports within the global scope of the component.
import { 
    FormField, 
    Input, 
    InputLabel, 
    Hint, 
    SectionFooter, 
    SectionFooterPrimaryContent, 
    Button, 
    SectionFooterSecondaryContent 
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';
 
export default class CustomSignIn extends SignIn {
    showComponent(theme) {
        const { authState, hide = [], federated, onStateChange } = this.props;
        if (hide && hide.includes(CustomSignIn)) { return null; }
        const hideSignUp = hide.some(component => component.name === 'CustomSignUp');
        const hideForgotPassword = hide.some(component => component.name === 'CustomForgotPassword');

        return (
            <div className="form-container">
                <header>
                    <img src={logo} alt="logo" className="logo" />
                </header>
                <FormSection theme={theme}>
                    <SectionHeader theme={theme}>{I18n.get('Sign in to your account')}</SectionHeader>
                    <SectionBody theme={theme}>
                        <FederatedButtons
                                federated={federated}
                                theme={theme}
                                authState={authState}
                                onStateChange={onStateChange}
                            />
                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Username')} *</InputLabel>
                            <Input
                                autoFocus
                                placeholder={I18n.get('Enter your username')}
                                theme={theme}
                                key="username"
                                name="username"
                                onChange={this.handleInputChange}
                            />
                        </FormField>
                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Password')} *</InputLabel>
                            <Input
                                placeholder={I18n.get('Enter your password')}
                                theme={theme}
                                key="password"
                                type="password"
                                name="password"
                                onChange={this.handleInputChange}
                            />
                            {
                                !hideForgotPassword && <Hint theme={theme}>
                                    {I18n.get('Forget your password? ')}
                                    <Link theme={theme} onClick={() => this.changeState('forgotPassword')}>
                                        {I18n.get('Reset password')}
                                    </Link>
                                </Hint>
                            }
                        </FormField>
                    </SectionBody>
                    <SectionFooter theme={theme}>
                        <SectionFooterPrimaryContent theme={theme}>
                            <Button theme={theme} onClick={this.signIn}>
                                {I18n.get('Sign In')}
                            </Button>
                        </SectionFooterPrimaryContent>
                        {
                            !hideSignUp && <SectionFooterSecondaryContent theme={theme}>
                                {I18n.get('No account? ')}
                                <Link theme={theme} onClick={() => this.changeState('signUp')}>
                                    {I18n.get('Create account')}
                                </Link>
                            </SectionFooterSecondaryContent>
                        }
                    </SectionFooter>
                </FormSection>
            </div>
        );
  }
}