import React from 'react';
import {Redirect} from 'react-router-dom';

import Amplify, { Auth } from 'aws-amplify';
import AppConstants from '../constants';

// Configure AWS manually - overrides Amplify.configure(aws_exports)
Amplify.configure({
    Auth: {
      identityPoolId: AppConstants.identityPoolId,
      region: AppConstants.region,
      userPoolId: AppConstants.userPoolId,
      userPoolWebClientId: AppConstants.userPoolWebClientId,
      mandatorySignIn: false,
      expires: 60,
    }
  });
  

class Logout extends React.Component {
    render() {
          
        Auth.signOut({global: true})
            .then(data => {
                this._cleanCache();
            })
            .catch(err => console.error(err));
       
        return ''; 
    }

    _cleanCache() {
        if (!window.localStorage) {
            return;
        }

        try {
            let storage = window.localStorage;
            storage.removeItem('aws-amplify-cachefederatedInfo');
            storage.removeItem('aws-amplify-federatedInfo');
        } catch (e) {

        }
    }
}

export default Logout;