import React, { Component } from 'react';

const modalStyles= {
    margin: 'auto',
    position: 'absolute',
    top: '-550px',
    left: 0, 
    bottom: 0, 
    right: 0,
    width: '100%',
    width: '600px',
    height: '600px',
    maxHeight:'600px',
    maxWidth: '600px',
    border: 'solid 1px #ddd',
    backgroundColor: '#fff',
};

export default class GDPRFrame extends Component {
    render () {
        const { show } = this.props;
        let url = 'https://kulturit.org/personvernerklaring';

        if (!show) {
            return('');
        } else {
            return(
                <div style={modalStyles}>
                    <iframe src={url} width="100%" height="100%" frameBorder="0"/>
                    <div>
                        <button className="button" onClick={() => this.closeDialog(false)}>Avbryt</button>
                        <button className="button" onClick={() => this.closeDialog(true)}>Aksepter</button>
                    </div>
                </div>
            );
        }
    }

    closeDialog = (accepted) => {
        const { closeHandler } = this.props;
        closeHandler(accepted);
    }
}