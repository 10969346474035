import { Cache } from 'aws-amplify';
import AppConstants from '../constants';

export default class AuthorizeFederatedIdentity {
    /**
     * Object constructor
     */
    constructor(appUUID) {
        this._data = {
            appUUID: appUUID,
            sourceUserId: ''
        };
    }

    /**
     * Initializes the Federated Identity Cura Authorization flow.
     */
    init() {
        return new Promise( (resolve, reject ) => {
            // Get information about the federated identity from the Cache.
            let federatedInfo = Cache.getItem('federatedInfo');

            let source = federatedInfo.provider,
                token = federatedInfo.token;

            this._data.source = source;

            let self = this;
            
            if (source.toLowerCase() === 'facebook') {
                this._handleFacebookUser(token).then((userdata) => {
                    self._data = Object.assign(userdata, self._data);
                    resolve(self._data);
                })
                .catch(err => reject(err));
            } else if (source.toLowerCase() === 'google') {
                if (token === null || token === '') {
                    reject('An unknown exception occured.');
                } 

                this._handleGoogleUser(token).then(userdata => {
                    self._data = Object.assign(userdata, self._data);
                    resolve(self._data);
                });
            }
        });
    }

    /**
     * Parses the payload of the received JWT.
     * @param {string} jwt 
     */
    static parseJWT(jwt) {
        if (jwt === null || jwt === '') {
            throw('An unknown exception occured.');
        } else {
            let base64Url = jwt.split('.')[1],
                base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        }
    }

    /**
     * Authorizes a Federated Identity as a Cura user.
     * - If the user exists, user data is fetched from Cura.
     * - If the user does not exist, it is created and granted access to default applications.
     * 
     * Promise resolving with a JWT.
     * 
     * @param {object} userData 
     */
    authorizeCuraUser(userData) {
        return new Promise( (resolve, reject) => {
            let url = `${AppConstants.restApiUrl}authorize`;
            fetch(url, {
                method: 'GET',
                headers: {
                    "ek-data": JSON.stringify(userData)
                },
            })
            .then(response => {
                let res = response.blob();
                return res;
            })
            .then(res => {
                let jwt = '',
                    fr = new FileReader();

                fr.onload = e => {
                    jwt = fr.result;

                    if (jwt === '') {
                        reject('Failed to fetch token.');
                    } else {
                        resolve(jwt);
                    }
                };

                fr.readAsText(res);
            })
            .catch(err => reject(err));
        });
    }

    /**
     * Fetches the required user data from the user's Facebook profile.
     * @param {string} token 
     */
    _handleFacebookUser(token) {
        return new Promise((resolve, reject) => {
            let params = {
                fields: 'email,last_name,first_name',
                access_token: token,
            };

            window.FB.api('/me', params, (response) => {
                if (!response || response.error) {
                    reject(response.error);
                } else {
                    resolve({
                        username: response.email,
                        givenName: response.first_name,
                        familyName: response.last_name
                    });
                }
            });
        });
    }

    /**
     * Fetches the required user data from the received Google token.
     * @param {string} token 
     */
    _handleGoogleUser(token) {
        return new Promise((resolve, reject) => {
            let jwt = AuthorizeFederatedIdentity.parseJWT(token);

            if (jwt === null || jwt === '' || !jwt.email_verified) {
                reject('An uknown exception occured.');
            }

            resolve({
                username: jwt.email,
                givenName: jwt.given_name,
                familyName: jwt.family_name
            });
        });
    }
}