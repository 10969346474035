import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';

import registerServiceWorker from './registerServiceWorker';
import AppWithAuth from './App';
import Logout from './components/Logout';

import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';

// ReactDOM.render(<AppWithAuth hideDefault={true}/>, document.getElementById('root'));

ReactDOM.render(
    <BrowserRouter>
    <Switch>
        <div>
            <Route path="/" component={() => <AppWithAuth hideDefault={true}/>}/>
            <Route exact path="/logout" component={Logout} />
        </div>
    </Switch>
    
    </BrowserRouter>, 
    document.getElementById('root')
);

registerServiceWorker();
