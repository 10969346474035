import React from 'react';
import Auth from '@aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import { SignUp } from 'aws-amplify-react';
import logo from '../../ekultur.png';

import { 
    FormSection,
    SectionHeader,
    SectionBody,
    Link,
} from 'aws-amplify-react';

// HACK: 
// Need to use different import for these UI-component as they are 
// not defined as exports within the global scope of the component.
import { 
    FormField, 
    Input, 
    InputLabel, 
    SectionFooter, 
    SectionFooterPrimaryContent, 
    Button, 
    SectionFooterSecondaryContent 
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';

export default class CustomSignUp extends SignUp {

    signUp() {
        const { password, email, given_name, family_name } = this.inputs;
        if (!Auth || typeof Auth.signUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        const username = email;

        let signup_info = {
            username,
            password, 
            attributes: {
                email,
                given_name,
                family_name
            }
        };
       
        Auth.signUp(signup_info).then(() => this.changeState('confirmSignUp', email))
        .catch(err => this.error(err));
    }
  
    showComponent(theme) {
        const { hide } = this.props;
        if (hide && hide.includes(CustomSignUp)) { return null; }

        return (
            <div className="form-container">
                <header>
                    <img src={logo} alt="logo" className="logo" />
                </header>
                <FormSection theme={theme}>
                    <SectionHeader theme={theme}>{I18n.get('Create a new account')}</SectionHeader>
                    <SectionBody theme={theme}>
                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Email Address')} *</InputLabel>
                            <Input
                                placeholder="janedoe@email.com"
                                theme={theme}
                                key="email"
                                name="email"
                                onChange={this.handleInputChange}
                            />
                        </FormField>

                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Password')} *</InputLabel>
                            <Input
                                placeholder={I18n.get('Create a password')}
                                theme={theme}
                                type="password"
                                key="password"
                                name="password"
                                onChange={this.handleInputChange}
                            />
                        </FormField>
                    
                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Given name')} *</InputLabel>
                            <Input
                                autoFocus
                                placeholder={I18n.get('Given name')}
                                theme={theme}
                                key="given_name"
                                name="given_name"
                                onChange={this.handleInputChange}
                            />
                        </FormField>

                        <FormField theme={theme}>
                            <InputLabel>{I18n.get('Family name')} *</InputLabel>
                            <Input
                                autoFocus
                                placeholder={I18n.get('Family name')}
                                theme={theme}
                                key="family_name"
                                name="family_name"
                                onChange={this.handleInputChange}
                            />
                        </FormField>

                    </SectionBody>
                    <SectionFooter theme={theme}>
                        <SectionFooterPrimaryContent theme={theme}>
                            <Button onClick={this.signUp} theme={theme}>
                                {I18n.get('Create Account')}
                            </Button>
                        </SectionFooterPrimaryContent>
                        <SectionFooterSecondaryContent theme={theme}>
                            {I18n.get('Have an account? ')}
                            <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                {I18n.get('Sign in')}
                            </Link>
                        </SectionFooterSecondaryContent>
                    </SectionFooter>
                </FormSection>
            </div>
        )
    }
}