import React, { Component } from 'react';
import { ConfirmSignIn, ConfirmSignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import AuthorizeFederatedIdentity from './helpers/authorizefederatedidentity';
import Utility from './helpers/utility';
import CustomSignUp from './components/customsignup';
import CustomSignIn from './components/customsignin';
import CustomForgotPassword from './components/customforgotpassword';
import GDPRFrame from './components/gdprframe';
import AppConstants from './constants';
import Loader from './components/loader';

import { I18n } from 'aws-amplify'; // Add I18n support
import localeNO from './i18n/no';   // Import Norwegian language strings


// Import assets
import './css/App.css';
import logo from './ekultur.png';

window.LOG_LEVEL = 'DEBUG';   // Set application log level.

let jwt = null;
let utility =  new Utility();

// Configure AWS manually - overrides Amplify.configure(aws_exports)
Amplify.configure({
  Auth: {
    identityPoolId: AppConstants.identityPoolId,
    region: AppConstants.region,
    userPoolId: AppConstants.userPoolId,
    userPoolWebClientId: AppConstants.userPoolWebClientId,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    expires: 60,
  }
});

let storageKey = 'ekulturAuthSettings';
let appUUID = null, language = null, callbackUrl = null;

function setLanguage() {
    if (!language) {
        language = 'en';
    }
    if (language !== 'en') {
        I18n.putVocabulariesForLanguage(language, localeNO);
        I18n.setLanguage(language);
    }
 }

function parseUrl() {
    let urlParameters = window.location.search.replace('?', '').split('&');
    for (let n = 0, len = urlParameters.length; n < len; n++) {
        let pairs = urlParameters[n].split('='),
            k = pairs[0],
            v = pairs[1];

        switch (k) {
            case 'lang':
                language = v;
                break;
            case 'uuid':
                appUUID = v;
                break;
            case 'callbackUrl':
                callbackUrl = v;
                break;
            default:
                break;
        }
    }

    if (!appUUID) {
        throw('No UUID specified, unable to continue.');
    }

    if (!callbackUrl) {
        throw('No callbackUrl specified, unable to continue.');
    }

    setLanguage();
    Utility.saveConfig(storageKey, language, appUUID, callbackUrl);
}

parseUrl();
setLanguage();

// Experimental settings:
// expires - Cookie expires after 60 days

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGDPRDialog: false,
      showLoader: true,
      jwt: '',
    };
  }
  
  componentDidMount() {
    this._isUserAuthenticated();
  }

  render() {
    return (
      <div className="App">
        <div className="form-container">
          <header>
            <img src={logo} alt="logo" className="logo"/>
          </header>
          <GDPRFrame show={this.state && this.state.showGDPRDialog} closeHandler={this._gdprCloseDialogHandler}/>
          <Loader show={this.state && this.state.showLoader}/>
        </div>
      </div>
    );
  }
  
  /**
   * Verifies whether or not a user is authenticated or not.
   * If the user is authenticated, show the GDPR-terms if not accepted earlier.
   * If GDPR-terms already accepted, redirect the user to the application of origin. 
   */
  _isUserAuthenticated() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        // Find out whether or not the current user has accepted GDPR/terms.
        if (user.signInUserSession) {
          jwt = user.signInUserSession.accessToken.getJwtToken();

          if (typeof(jwt.acceptedGDPR) === 'undefined') {
            // A new user has been created by registering username/password,
            // authorize the user
            utility.authorizeUser(user).then(jwt => {
              this.setState({jwt: jwt});
              let parsedJWT = AuthorizeFederatedIdentity.parseJWT(jwt);
              if (!parsedJWT.acceptedGDPR) {
                this.setState({showGDPRDialog: true, showLoader: false});
              } else {
                utility.redirectToOrigin(this.state.jwt);
              }
            }).catch(err => {
              // An error occured authorizing the user,
              // most likely because the user was logged out.
              // Reset the dialog-state, displaying the login form.
              this.setState({jwt: null});
              this.props.onStateChange('signIn', null);
            });
          } else {
            // User has signed-on by username/password. Verify GDPR.
            this.setState({jwt: jwt});
            let parsedJWT = AuthorizeFederatedIdentity.parseJWT(jwt);
            if (!parsedJWT.acceptedGDPR) {
              this.setState({showGDPRDialog: true, showLoader: false});
            } else {
              utility.redirectToOrigin(this.state.jwt);
            }
          }
        } else {
          // User has signed-on via a Federated Identity, authorize the user as a Cura user,
          // retrieve the token and verify GDPR.
          utility.authorizeFederatedIdentity(user).then(jwt => {
            this.setState({jwt: jwt});
            this._verifyGDPR();
          });
        }
      })
      .catch(err => {
        console.error(err)
      });
  }

  /**
   * Verifies whether or not the current user has accepted GDPR/terms.
   */
  _verifyGDPR() {
    let jwt = AuthorizeFederatedIdentity.parseJWT(this.state.jwt);
    if (!jwt.acceptedGDPR) {
      this.setState({
        showGDPRDialog: true, 
        showLoader: false
      });
    } else {
      utility.redirectToOrigin(this.state.jwt);
    }
  }

  /**
   * Closes the GDPR-dialog once accepted or aborted.
   * If not accepted, the user is signed out. 
   */
  _gdprCloseDialogHandler = (accepted) => {
    if (!accepted) {
      Auth.signOut()
        .then(() => {
            this.props.onStateChange('signIn', null); // Sign out user
            this.setState({
              showGDPRDialog: false, 
              showLoader: true
            });   // Close the GDPR-dialog.
        })
        .catch(err => console.error(err));
    } else {

      let url = utility.getAcceptGdprURL();
      fetch(url, {
        method: 'GET',
        headers: { 'x-kit-auth': this.state.jwt }
      })
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          utility.redirectToOrigin(this.state.jwt); // GDPR accepted, redirect to origin
        } else {
          this.props.onStateChange('signIn', null); // GDPR acceptance failed
          this.setState({
            showGDPRDialog: false, 
            showLoader: true
          });   // Close the GDPR-dialog.
        }
      })
      .catch(err => console.error(err));
    }
  }
}

// Configure federated identities
const federated = {
  facebook_app_id: AppConstants.facebookAppID,
  google_client_id: AppConstants.googleAppID
};

const AppWithAuth = withAuthenticator(App, true, [
  <CustomSignIn federated={federated}/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <CustomSignUp/>,
  <ConfirmSignUp/>,
  <CustomForgotPassword/>
]);

export default AppWithAuth;
