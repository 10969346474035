import AuthorizeFederatedIdentity from './authorizefederatedidentity';
import AppConstants from '../constants';
import AuthorizeUser from './authorizeuser';

/**
 * Class containing utility methods used when authenticating a user.
 */
export default class Utility {
    constructor() {
        this._appUUID = null;
        this._language = null;
        this._callbackUrl = null;
    }

    /***
     * Returns the application's UUID.
     */
    getAppUUID() {
        return this._appUUID;
    }

    /**
     * Returns the URL used when displaying the GDPR-information.
     * @param {string} jwt 
     */
    getGdprURL() {
        return `${AppConstants.restApiUrl}gdprstate`;
    }

    /**
     * Returns the URL used when posting acceptance of the GDPR-terms.
     * @param {string} jwt 
     */
    getAcceptGdprURL() {
        return `${AppConstants.restApiUrl}acceptgdpr`;
    }

    /**
     * Authorizes the Federated Identity against Cura.
     */
    authorizeFederatedIdentity() {
        return new Promise( (resolve, reject) => {
            let authorize = new AuthorizeFederatedIdentity(this._appUUID);
            authorize.init().then(userData => {
                authorize.authorizeCuraUser(userData).then(jwt => {
                    resolve(jwt);
                })
                .catch(err => reject(err));
            })
            .catch(err => reject(err));
        });
    }

    /***
     * Authorizes a new user created via AWS username/password entry against Cura.
     */
    authorizeUser(user) {
        return new Promise( (resolve, reject) => {
            if (typeof(user.attributes) === 'undefined') {
                reject('User not authenticated.');
            }

            let authorize = new AuthorizeUser(this._appUUID);
            authorize.init(user).then(jwt => {
                resolve(jwt);
            }).catch(err => reject(err));
        });
    }

    /**
     * Redirects back to the application of origin.
     */
    redirectToOrigin(jwt) {
        if (jwt === null || jwt === '') {
            throw('No token present, unable to continue.');
        }

        let config = Utility.loadConfig('ekulturAuthSettings');
        let url = `${config.callbackUrl}?token=${jwt}`;
        window.location.replace(url);
    }

    static initStorage() {
        if (!window.localStorage) {
            return;
        }

        return window.localStorage;
    }

    static saveConfig(storageKey, language, appUUID, callbackUrl) {
        let storage = Utility.initStorage();

        let config = {
            lang: language,
            appUUID: appUUID,
            callbackUrl: callbackUrl
        };

        storage.setItem(storageKey, JSON.stringify(config));
    }

    static loadConfig(storageKey) {
        let storage = Utility.initStorage();
        let config = JSON.parse(storage.getItem(storageKey));
        return config;
    }
}